export default {
	// Loader will auto prefix common. key to message
	"common.application.title" : "DECK" ,
	"common.application.title.second" : "Drone Service Management" ,
	"common.page.home.title" : "หน้าแรก" ,
	"common.currency.unit" : "บาท" ,
	"common.time.minute" : "นาที" ,
	"common.privacy.policy" : "Privacy Policy",
	"common.since" : "เมื่อ" ,
	"common.from" : "จาก" ,
	"common.yes" : "ใช่" ,
	"common.no" : "ไม่ใช่" ,
	"common.or" : "หรือ" ,
	"common.save" : "บันทึก" ,
	"common.confirm" : "ยืนยัน",
	"common.ok" : "ตกลง" ,
	"common.delete" : "ลบทิ้ง" ,
	"common.cancel" : "ยกเลิก" ,
	"common.close" : "ปิด" ,
	"common.next" : "ถัดไป" ,
	"common.back" : "ย้อนกลับ" ,
	"common.unspecify" : "ไม่ระบุ",
	"common.update" : "แก้ไข" ,
	"common.by" : "โดย" ,
	"common.load_more" : "Load More ...",
	"common.hour" : "ชั่วโมง" ,
	"common.hour.short" : "ชม." ,
	"common.minute" : "นาที" ,
	"common.second" : "วินาที" ,
	"common.day" : "วัน" ,
	"common.day.this" : "วันนี้" ,
	"common.month" : "เดือน" ,
	"common.month.this" : "เดือนนี้" ,
	"common.week" : "สัปดาห์" ,
	"common.week.this" : "สัปดาห์นี้" ,
	"common.charge.unit" : "รอบ" ,
	"common.part.unit" : "ชิ้น" ,
	"common.drone.unit" : "ตัว" ,
	"common.ticket.unit" : "รายการ" ,
	"common.error.unspecify" : "พบปัญหาในระบบ, กรุณาลองใหม่อีกครั้ง" ,
	"common.error.validate_error" : "ข้อมูลบางส่วนไม่ถูกต้อง, ลองตรวจสอบอีกครั้ง" ,
	"common.error.server_connect" : "พบปัญหาในการเชื่อมต่อ Server" ,
	"common.password.pattern" : "รหัสผ่านมีความยาวระหว่าง 6 - 40 ตัวอักษรและต้องมีตัวเลขอย่างน้อย 1 ตัว" ,
	"common.province" : "จังหวัด",
	"common.see_more": 'ดูเพิ่ม',

	"common.table.column.excel_row" : "แถว",
	"common.table.column.no" : "#" ,
	"common.table.column.action" : "Action" ,
	"common.table.column.detail" : "รายละเอียด" ,
	"common.table.action.view" : "ดูเพิ่ม" ,
	"common.table.action.update" : "แก้ไข" ,
	"common.table.action.delete" : "ลบ" ,

	"common.search.view_all" : "แสดงทั้งหมด" ,
	"common.search.more_options" : "ค้นหาเพิ่มเติม" ,

	"common.status.active" : "ใช้งานปกติ" ,
	"common.status.inactive" : "ระงับใช้งาน" ,

	"common.field.updated_datetime" : "วันที่แก้ไขล่าสุด" ,

	"enum.user_role.1" : "ผู้ดูแลระบบ" ,
	"enum.user_role.2" : "ผู้ใช้งานทั่วไป" ,
	"enum.user_role.3" : "ช่าง" ,

	"enum.user_status.active" : "@:common.status.active" ,
	"enum.user_status.inactive" : "@:common.status.inactive" ,
	"enum.user_status.locked" : "Password Locked" ,

	"enum.company_type.1" : "DECK" ,
	"enum.company_type.2" : "HGR" ,
	"enum.company_type.3" : "Flagship" ,
	"enum.company_type.4" : "Chain Dealer" ,
	"enum.company_type.5" : "External Dealer" ,
	"enum.company_type.6" : "Service Center" ,

	"enum.company_type.main_center" : "สำนักงาน" ,
	"enum.company_type.service_center" : "ศูนย์บริการ" ,

	"enum.company_stauts.active" : "@:common.status.active" ,
	"enum.company_status.inactive" :  "@:common.status.inactive" ,

	"enum.part_type.electronic" : "Electronic" ,
	"enum.part_type.mechanic" : "Mechanic" ,
	"enum.part_type.material" : "Material" ,
	"enum.part_type.sensor" : "Sensor" ,
	"enum.part_type.device" : "Device" ,
	"enum.part_type.battery" : "Battery" ,
	"enum.part_type.others" : "Others" ,

	"enum.part_status.active" : "@:common.status.active" ,
	"enum.part_status.inactive" : "@:common.status.inactive" ,

	"enum.warranty_type.none" : "ปกติ" ,
	"enum.warranty_type.flight" : "ดูชั่วโมงบิน" ,
	"enum.warranty_type.charge" : "ดูรอบ Charge แบตเตอร์รี่" ,

	"enum.drone_type.spraying" : "พ่นสาร" ,
	"enum.drone_type.mapping" : "ทำแผนที่" ,

	"enum.drone_part_type.initial" : "อะไหล่ตั้งต้น" ,
	"enum.drone_part_type.optional" : "อะไหล่เสริม" ,

	"enum.lot_part_mode.initial" : "ตาม Model" ,
	"enum.lot_part_mode.change" : "เปลียนค่า" ,
	"enum.lot_part_mode.custom" : "เพิ่มเติม" ,
	"enum.lot_part_mode.excluded" : "เอาออก" ,

	"enum.drone_model.status.active" : "@:common.status.active" ,
	"enum.drone_model.status.inactive" : "ยกเลิกใช้งาน" ,
	"enum.drone_model.status.draft" : "Draft" ,

	"enum.customer_type.juristic" : "นิติบุคคล" ,
	"enum.customer_type.people" : "บุคคลทั่วไป" ,
	"enum.customer_type.others" : "อื่น ๆ" ,

	"enum.drone.status.new" : "รอลงทะเบียน" ,
	"enum.drone.status.new.description" : "รอลงทะเบียน เพื่อระบุเจ้าของ" ,
	"enum.drone.status.backup" : "เครื่องสำรอง" ,
	"enum.drone.status.backup.description" : "เป็นเครื่องสำรอง หรือ เครื่องที่ใช้ทดสอบ" ,
	"enum.drone.status.active" : "ใช้งาน" ,
	"enum.drone.status.active.description" : "เครื่องใช้งานตามปกติ" ,
	"enum.drone.status.decommission" : "ยกเลิกใช้งาน" ,
	"enum.drone.status.decommission.description" : "ยกเลิกใช้งาน Drone ตัวนี้แล้ว" ,

	"enum.drone.policy.none" : "ปกติ" ,
	"enum.drone.policy.none.desciption" : "ใช้งาน Drone ปกติ",
	"enum.drone.policy.locked_7d" : "Lock 7 วัน",
	"enum.drone.policy.locked_7d.description" : "Drone จะต้องต่อ HGMC ทุก 7 วันเพื่อต่ออายุการใช้งาน (ใชักับ Drone ที่ติด Finance)",

	"enum.drone.lock_state.active" : "ปกติ" ,
	"enum.drone.lock_state.active.desciption" : "ใช้งาน Drone ปกติ",
	"enum.drone.lock_state.locked" : "ถูก Lock",
	"enum.drone.lock_state.locked.description" : "Drone ถูก Lock เนื่องจาก Finance",

	"enum.drone.connect.flag.none" : "ไม่ได้ส่ง",
	"enum.drone.connect.flag.success" : "ส่งเรียบร้อย",
	"enum.drone.connect.flag.failed" : "พบปัญหา",


	"enum.drone.part.attached_type.manufacture" : "การผลิต" ,
	"enum.drone.part.attached_type.change" : "เปลี่ยน" ,
	"enum.drone.part.attached_type.claim" : "Claim" ,
	"enum.drone.part.attached_type.optional" : "ติดตั้งเสริม" ,

	"enum.ticket.location_type.customer" : "ลูกค้า" ,
	"enum.ticket.location_type.service" : "ศูนย์บริการ" ,
	"enum.ticket.location_type.hgr" : "HGR" ,

	"enum.ticket.problem_type.general" : "สอบถามทั่วไป" ,
	"enum.ticket.problem_type.consult" : "ปรึกษาการเคลม" ,

	"enum.ticket.ticket_type.hardware" : "Hardware" ,
	"enum.ticket.ticket_type.software" : "Software" ,
	"enum.ticket.ticket_type.part" : "อะไหล่" ,
	"enum.ticket.ticket_type.others" : "อื่น ๆ" ,

	"enum.ticket.resolved_result.unidentified" : "ไม่ต้องระบุ" ,
	"enum.ticket.resolved_result.positive" : "น่าเคลมได้" ,
	"enum.ticket.resolved_result.negative" : "ไม่น่าเคลมได้" ,

	"enum.ticket.status.new" : "New" ,
	"enum.ticket.status.investigate" : "Investigate" ,
	"enum.ticket.status.pending" : "Pending" ,
	"enum.ticket.status.resolved" : "Resolved" ,
	"enum.ticket.status.reopen" : "Reopen",
	"enum.ticket.status.close" : "Close" ,
	"enum.ticket.status.cancel" : "Cancel" ,

	"enum.ticket.status.new.description" : "Ticket ใหม่" ,
	"enum.ticket.status.investigate.description" : "กำลังตรวจสอบ" ,
	"enum.ticket.status.pending.description" : "รอข้อสรุป" ,
	"enum.ticket.status.resolved.description" : "ตอบ Ticket เรียบร้อย",
	"enum.ticket.status.reopen.description" : "ขอตรวจสอบใหม่",
	"enum.ticket.status.close.description" : "ปิดเรียบร้อย" ,
	"enum.ticket.status.cancel.description" : "ยกเลิก" ,

	"enum.time_range.morning" : "ก่อน 11 โมง" ,
	"enum.time_range.noon" : "11:00 - 13:00" ,
	"enum.time_range.afternoon" : "13:00 - 17:00" ,
	"enum.time_range.evening" : "หลัง 17 นาฬิกา" ,

	"enum.service.open_type.customer" : "ลูกค้า" ,
	"enum.service.open_type.from_service" : "ส่งมาจากศูนย์อื่น",
	"enum.service.open_type.onfield" : "ซ่อมหน้างาน",

	"enum.service.status.new" : "งานใหม่" ,
	"enum.service.status.in_progress" : "กำลังซ่อม" ,
	"enum.service.status.pending" : "รอซ่อม" ,
	"enum.service.status.pre_delivery" : "ทดสอบก่อนคืน" ,
	"enum.service.status.wait_delivery" : "รอส่งคืนลูกค้า" ,
	"enum.service.status.close" : "ปิดงาน" ,
	"enum.service.status.cancel" : "ยกเลิก" ,

	"enum.service.onfield.status.bind_ticket" : "ผูก Ticket" ,
	"enum.service.onfield.status.pre_service" : "ตรวจรับเครื่อง" ,
	"enum.service.onfield.status.service_job" : "กำลังซ่อม" ,
	"enum.service.onfield.status.claim" : "บันทึกรายการเคลม" ,
	"enum.service.onfield.status.cost_estimate" : "เสนอราคา" ,
	"enum.service.onfield.status.pre_delivery" : "ทดสอบก่อนคืน" ,
	"enum.service.onfield.status.return" : "รอส่งคืนลูกค้า" ,
	"enum.service.onfield.status.done" : "ปิดงาน" ,
	"enum.service.onfield.status.cancel" : "ยกเลิก" ,

	"enum.service.status.new.description" : "งานใหม่เพิ่งรับเครื่อง" ,
	"enum.service.status.in_progress.description" : "อยู่ระหว่างซ่อม",
	"enum.service.status.pre_delivery.description" : "กำลังทดสอบ ก่อนส่งมอบคืนลูกค้า",
	"enum.service.status.wait_delivery.description" : "รอลูกค้ามารับ หรือ ส่งคืนลูกค้า",
	"enum.service.status.close.description" : "ปิดงานเรียบร้อย",
	"enum.service.status.cancel.description" : "ถูกยกเลิก",
	"enum.service.status.pending.description" : "รอซ่อม",

	"enum.service.pre_service.devices.remote"  : "รีโมท",
	"enum.service.pre_service.devices.tablet" : "แท็บเล็ต" ,
	"enum.service.pre_service.devices.charger" : "ที่ชาร์จ" ,
	"enum.service.pre_service.devices.charging_panel" : "แผงชาร์จ" ,
	"enum.service.pre_service.devices.others" : "อุปกรณ์อื่น ๆ" ,
	"enum.service.pre_service.devices.batteries" : "แบตเตอร์รี่" ,

	"enum.service.repair.result.in_progress" : "กำลังซ่อม" ,
	"enum.service.repair.result.success" : "ซ่อมเรียบร้อย" ,
	"enum.service.repair.result.fail" : "ซ่อมไม่ได้" ,

	"enum.service.part.change_type.normal" : "เปลี่ยนทั่วไป" ,
	"enum.service.part.change_type.recall" : "Recall" ,
	"enum.service.part.change_type.repair" : "งานซ่อม" ,

	"enum.service.part.change_mode.change" : "เปลี่ยน" ,
	"enum.service.part.change_mode.remove" : "เอาออก" ,
	"enum.service.part.change_mode.add" : "เพิ่ม" ,

	"enum.service.part.change_mode.change.description" : "เปลี่ยนของเดิมออก" ,
	"enum.service.part.change_mode.remove.description" : "ถอดออก" ,
	"enum.service.part.change_mode.add.description" : "ติดตั้งเพิ่ม" ,

	"enum.service.payment.status.new" : "รอราคา" ,
	"enum.service.payment.status.in_progress" : "รอชำระเงิน" ,
	"enum.service.payment.status.complete" : "ชำระครบแล้ว" ,

	"enum.service.return.type.customer" : "ลูกค้า",
	"enum.service.return.type.refer" : "ส่งต่อศูนย์อื่น" ,

	"enum.claim.status.draft" : "เตรียมยื่นเคลม" ,
	"enum.claim.status.draft.description" : "กรุณากรอกข้อมูลเพื่อเตรียมยื่นให้ทาง HGR ตรวจสอบการเคลม" ,
	"enum.claim.status.request" : "กำลังตรวจสอบ" ,
	"enum.claim.status.request.description" : "ทาง HGR กำลังตรวจสอบการเคลม เพื่ออนุมัติ" ,
	"enum.claim.status.approved" : "อนุมัติ" ,
	"enum.claim.status.approved.description" : "HGR ได้ทำการอนุมัติการเคลมเรียบร้อย ถ้าต้องการแก้ไขรายการ ต้องทำการยื่นเคลมใหม่" ,
	"enum.claim.status.rejected" : "ปฎิเสธการเคลม" ,
	"enum.claim.status.rejected.description" : "จากหลักฐานที่ส่งมาทาง HGR ได้ปฎิเสธการเคลมนี้ ถ้าต้องการแก้ไขรายการหรือส่งหลักฐานเพิ่มกรุณายื่นเคลมใหม่" ,
	"enum.claim.status.draft_appeal" : "เตรียมยื่นใหม่" ,
	"enum.claim.status.draft_appeal.description" : "กรุณากรอกข้อมูลหรือรายละเอียดเพิ่มเติมประกอบการยื่นคำร้องให้พิจารณาใหม่" ,
	"enum.claim.status.appeal" : "กำลังตรวจสอบใหม่" ,
	"enum.claim.status.appeal.description" : "ทาง HGR กำลังตรวจสอบคำร้องเคลมใหม่อยู่" ,

	"enum.claim.payment_request.none" : "ยังไม่ได้ดำเนินการ" ,
	"enum.claim.payment_request.request" : "รอ HGR" ,
	"enum.claim.payment_request.complete" : "ได้รับเงินเรียบร้อย" ,


	"enum.flight_mode.auto" : "Auto" ,
	"enum.flight_mode.manual_gps" : "Manual+GPS" ,
	"enum.flight_mode.manual" : "Manual" ,

	"enum.delivery.type.walkin" : "ลูกค้าเข้ามาที่ศูนย์" ,
	"enum.delivery.type.shipping" : "ขนส่งเอกชน" ,
	"enum.delivery.type.self_shipping" : "ศูนย์ไปรับ" ,
	"enum.delivery.type.service_shipping" : "ศูนย์ต้นทางส่งมา" ,
	"enum.delivery.type.onfield" : "ซ่อมหน้างาน" ,

	"enum.delivery.type.out.walkin" : "ลูกค้ามารับที่ศูนย์" ,
	"enum.delivery.type.out.shipping" : "ขนส่งเอกชน" ,
	"enum.delivery.type.out.self_shipping" : "ศูนย์จัดส่ง" ,

	"enum.inspection_type.tgd1x" : "TGD1X" ,

	"enum.drone.devices.remote" : "รีโมท",
	"enum.drone.devices.tablet" : "แท็บเล็ต",
	"enum.drone.devices.charger" : "ที่ชาร์จ",
	"enum.drone.devices.charging_panel" : "แผงชาร์จ",

	"enum.drone.return.walkin" : "ลูกค้ามารับที่ศูนย์",
	"enum.drone.return.shipping" : "ขนส่งเอกชน",
	"enum.drone.return.self_shipping" : "ศูนย์จัดส่ง",

	"validate.required" : "กรุณาระบุ {field}" ,
	"validate.invalid" : "{field} ไม่ถูกต้อง" ,
	"validate.length" : "{field} ต้องมีความยาวตั้งแต่ {min} - {max} ตัวอักษร" ,
	"validate.length.min" : "{field} ต้องมีความยาวไม่น้อยกว่า {min}  ตัวอักษร" ,
	"validate.length.max" : "{field} ต้องมีความยาวไม่เกิน {min}  ตัวอักษร" ,
};
